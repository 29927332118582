
import axios from 'axios';
export default {
    
    async createLinks(params)  {
        return await axios.post(`links/create` , params)
    },
    async createLinksList(params)  {
        return await axios.post(`links/create/list` , params)
    },
    async updateLinksColumn(column , value , data)  {
        return await axios.put(`links/update_list?${column}=${value}` , data)
    },
    async deleteLinksList(list)  {
        return await axios.delete(`links/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportLinks(column , value)  {
        return await axios.get(`links/report?${column}=${value}`)
    },
    async getAllLinks()  {
        return await axios.get(`links/all`)
    },
    async getOneLinks(link_id)  {
        return await axios.get(`links/all/${link_id}`)
    },
    async getLinksByColumn(column , value)  {
        return await axios.get(`links/filter?column=${column}&value=${value}`)
    },
    async deleteLinks(link_id)  {
        return await axios.delete(`links/delete/${link_id}`)
    },
    async updateLinks(link_id , params)  {
        return await axios.put(`links/update/${link_id}` , params)
    }
}