
<template>
    <div>
            <v-container class="my-2">
                    <v-layout row wrap px-0 mb-4>
                        <v-flex xs12>
                            <v-card class="mx-1 px-0" style="background-color:transparent!important" flat>
                                <v-card-title class="px-0">
                                    <v-breadcrumbs class="px-0" :items="$store.getters.language.data.links.list_path">
                                        <template v-slot:item="{ item }">
                                            <v-breadcrumbs-item :to="item.path" :disabled="item.disabled">
                                                {{ item.title.toUpperCase() }}
                                            </v-breadcrumbs-item>
                                        </template>
                                    </v-breadcrumbs>
                                    <v-spacer></v-spacer>
                                    <!-- <v-btn class="float-right" color="primary" icon  @click="exportExcel">
                                        <v-icon>mdi-file-excel</v-icon>
                                    </v-btn> -->
                                    <v-btn class="float-right" color="primary" icon  to="/add-new-links">
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </v-card-title>
                            </v-card>
                        </v-flex>
                    </v-layout>
                <form @submit.prevent="addLinks" autocomplete="off">
                    <v-layout row wrap>
        
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="links.link_name" type="text" :label="$store.getters.language.data.links.link_name" dense
                            class="mx-1"  filled outlined  required>
                        </v-text-field>
                    </v-flex>
                
                   <!-- <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="links.link_icon_dark" type="textarea" :label="$store.getters.language.data.links.link_icon_dark" dense
                            class="mx-1"  filled outlined >
                        </v-text-field>
                    </v-flex> -->
                   <!-- <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="links.link_icon_white" type="textarea" :label="$store.getters.language.data.links.link_icon_white" dense
                            class="mx-1"  filled outlined >
                        </v-text-field>
                    </v-flex> -->
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="links.link_index" type="number" :label="$store.getters.language.data.links.link_index" dense
                            class="mx-1"  filled outlined  required>
                        </v-text-field>
                    </v-flex>
                
                        <v-flex xs12 lg2 xl2 md2 sm4>
                            <v-btn  color="primary" :loading="loading_btn" type="submit" >{{$store.getters.language.data.links.add_btn}}</v-btn>
                        </v-flex>
                    </v-layout>
                </form>
<v-layout row wrap mt-5>
    <v-flex xs12>
        <v-card flat>
            <v-card-text>
                <v-data-table :loading="loading" :headers="headers"  v-model="selected_rows"    :search="search" :items="rows" class="elevation-0"
                    item-key="link_id">
                        <template v-slot:[`item.link_icon_dark`]="{ item }">
                            <div>
                                <UploadBtn :table="{
                                        table_name: 'links',
                                        column_name: 'link_icon_dark',
                                        table_key: 'link_id',
                                        table_key_value: item.link_id
                                    }" :isImage="item.link_icon_dark ? true : false"
                                    :imgName="item.link_icon_dark"
                                    :height="50"
                                    :width="50"
                                    :quality="70"
                                    :isAvatar="true"
                                @uploaded="uploaded" />
                            </div>
                        </template>
                        <template v-slot:[`item.link_icon_white`]="{ item }">
                            <div>
                                <UploadBtn :table="{
                                        table_name: 'links',
                                        column_name: 'link_icon_white',
                                        table_key: 'link_id',
                                        table_key_value: item.link_id
                                    }" :isImage="item.link_icon_white ? true : false"
                                    :imgName="item.link_icon_white"
                                    :height="50"
                                    :width="50"
                                    :quality="70"
                                    :isAvatar="true"
                                @uploaded="uploaded" />
                            </div>
                        </template>
                    <template v-slot:[`item.link_id`]="{ item }">
                        <div>
                            <v-btn icon :to="'/links-list/'+item.link_id"  color="teal" class="mx-1">
                                <v-icon> mdi-pencil-outline </v-icon>
                            </v-btn>
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="error" icon class="mx-1" v-bind="attrs" v-on="on" >
                                        <v-icon> mdi-delete-outline </v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-text>
                                        {{$store.getters.language.data.links.delete_question}}
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn text class="elevation-0">
                                            {{$store.getters.language.data.links.cancel_btn}}
                                        </v-btn>
                                        <v-btn color="error" class="elevation-0" @click="deleteLinks(item.link_id)">
                                            {{$store.getters.language.data.links.yes_btn}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-menu>
                        </div>
                    </template>
                </v-data-table>
            </v-card-text>
            
           
                
        </v-card>
    </v-flex>
</v-layout>
</v-container>

<v-snackbar v-model="snackbar.value" :color="snackbar.color">
    {{snackbar.text}}
</v-snackbar>
</div>
</template>
<script>
    import requests from './../../requests/links.request.js'
    import * as xlsx from 'xlsx/xlsx.js';
    
    import UploadBtn from '../../components/UploadBtn.vue';
    export default {
        
        components: {
            UploadBtn
        },
        data() {
            return {
                links: {},
                search: '',
                loading : true,
                loading_btn:false,
                selected_rows : [],
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
                rows:[],
                
                selected_links : {},
                headers: [

                    
                        { 
                            text: this.$store.getters.language.data.links.link_name,
                            align: 'start',
                            sortable: true,
                            value: 'link_name',
                        },
                        // { 
                        //     text: this.$store.getters.language.data.links.link_icon_dark,
                        //     align: 'start',
                        //     sortable: true,
                        //     value: 'link_icon_dark',
                        // },
                        { 
                            text: this.$store.getters.language.data.links.link_icon_white,
                            align: 'start',
                            sortable: true,
                            value: 'link_icon_white',
                        },
                        { 
                            text: this.$store.getters.language.data.links.link_index,
                            align: 'start',
                            sortable: true,
                            value: 'link_index',
                        },   {
            text: this.$store.getters.language.data.actions,
            align: 'start',
            sortable: true,
            value: 'link_id',
        }
                ],
            }
        },
        computed: {
            page(){
                const pageKey = 'LINKS'
                return {}
            }
        },
        mounted(){
            this.readLinks();
        },
        methods: {
            uploaded(data){
                if(data.new_data){
                    this.readLinks();
                }
            },
            exportExcel(){
                const list = this.rows
                let result = []
                for (let i = 0; i < list.length; i++) {
                    const item = list[i];
                    let obj = {link_id : item.link_id,link_name : item.link_name,link_icon_dark : item.link_icon_dark,link_icon_white : item.link_icon_white,link_index : item.link_index,
                    }
                    result.push(obj)
                }
				var f = xlsx.utils.json_to_sheet(result)
				var wb = xlsx.utils.book_new()
				xlsx.utils.book_append_sheet(wb, f, 'sheet')
				xlsx.writeFile(wb, result.length+' rows of '+'Links.xlsx')
            },
            addLinks() {
                this.loading_btn = true
                requests.createLinks(this.links).then(r => {
                    if (r.status == 200) {
                        this.links = {}
                        this.rows.push(
                            r.data.new_data
                        )
                        this.snackbar = {
                            value: true,
                            text: 'Links Added',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to add Links',
                            color: 'error'
                        }
                    }
                })
                .finally(() => {
                    this.loading_btn = false
                })

            },
            deleteLinks(link_id) {
                requests.deleteLinks(link_id).then(r => {
                    if (r.status == 200) {
                        this.rows = this.rows.filter(f =>{
                            return f.link_id != link_id
                        })
                        this.snackbar = {
                            value: true,
                            text: 'Links Deleted',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Delete Faild',
                            color: 'error'
                        }
                    }
                })
            },
            
            deleteLinksList() {
                let ids = this.selected_rows.map(m => m.link_id)
                requests.deleteLinksList(ids).then(r => {
                    if (r.status == 200) {
                        this.rows = this.rows.filter(f =>{  
                            return ids.indexOf(f.link_id) == -1
                        })
                        this.snackbar = {
                            value: true,
                            text: this.selected_rows.length+' Links Deleted',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Delete Faild',
                            color: 'error'
                        }
                    }
                })
            },
            
            readLinks() {
                this.loading = true
                requests.getAllLinks().then(r => {
                    if (r.status == 200) {
                        this.rows = r.data.links
                        
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to read Links',
                            color: 'error'
                        }
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: 'Fail to read Links',
                        color: 'error'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
            },
            selectLinks(i) {
                this.selected_links = i
                this.delete_dialog = true
            },
        },
    }
</script>
                    